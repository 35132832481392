import React, { ReactNode } from "react";
import styles from "./ClickableHeader.module.css";

interface ClickableHeaderProps {
  onBack?: () => void;
  title?: string;
  showBackButton?: boolean;
  customBackIcon?: ReactNode;
}

export const ClickableHeader: React.FC<ClickableHeaderProps> = ({
  onBack,
  title = "",
  showBackButton = true,
  customBackIcon = null,
}) => (
  <div className={styles.header}>
    {showBackButton && (
      <div className={styles.backButton} onClick={onBack}>
        {customBackIcon || (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 17L2 11.5L7 6"
              stroke="#454A3F"
              strokeWidth="2.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
    )}
    <h1 className={styles.title}>{title}</h1>
  </div>
);
