import { ClickableHeader } from "components/ClickableHeader/ClickableHeader";
import { SearchInput } from "components/SearchInput/SearchInput";
import useDebounce from "hooks/useDebounce";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "store";

import {
  getExpenseList,
  getHistoryList,
  getIncomeList,
  HISTORY_TABS,
  setHistorySearchFilters,
} from "store/slices/historySlice";
import { DateStyled, SearchFiltersValues } from "../../History";
import { SkeletonContainer } from "components/SkeletonContainer";
import { EmptyScreenDefault } from "../EmptyScreenDefault/EmptyScreenDefault";
import { EmptyScreenCustom } from "../EmptyScreenCustom/EmptyScreenCustom";
import { Box } from "@mui/material";
import { Loader } from "components";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { OperationItem } from "../OperationItem/OperationItem";
import { useIntersectionLoad } from "hooks/useIntersectionLoad";
import { StatementShort } from "api/account";
import { parseDate } from "utils";
import { useIsMobile } from "hooks/useIsMobile";
import moment from "moment";
import { systemActions, TabVariants } from "store/slices/system";

export const SearchOperations = () => {
  const {
    history: {
      activeTab,
      isLoading,
      filters,
      historyList,
      incomeList,
      expenseList,
    },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [localSearch, setLocalSearch] = useState(filters.search || "");
  const debouncedSearch = useDebounce<string>(localSearch, 1000);

  const lastBlockRef = useRef<HTMLDivElement | null>(null);

  const { isMobile } = useIsMobile();

  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });

  const handleLoadMore = () => {
    const len = data[activeTab].reduce(
      (acc, it) => (it.statements as StatementShort[]).length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === filters.page;

    if (!isSamePage && isEndOfSlice) {
      dispatch(
        setHistorySearchFilters({
          ...filters,
          page: Math.floor(len / 10),
        })
      );
    }
  };

  useIntersectionLoad({
    targetRef: lastBlockRef,
    loadMore: handleLoadMore,
    isLoading,
  });

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  // Обработка изменений дебаунсированного значения поисковой строки
  useEffect(() => {
    if (debouncedSearch !== filters.search) {
      dispatch(
        setHistorySearchFilters({ ...filters, search: debouncedSearch })
      );
    }
  }, [debouncedSearch, dispatch, filters]);

  const payload = useMemo(
    () => ({
      size: 10,
      ...filters,
      ...{
        operationFilterType: filters.operationFilterType || null,
      },
    }),
    [filters]
  );
  const dateNow = new Date();
  const fromDate = isMobile
    ? moment().add(1, "day").subtract(2, "week")
    : moment().utc().startOf("month");

  const fromTextFilter = parseDate(payload.from);
  const toTextFilter = parseDate(payload.to);
  const fromTextDefault = parseDate(fromDate.toISOString());
  const toTextDefault = parseDate(dateNow.toISOString());

  const isDefaultFilters =
    !payload.search.length &&
    !payload.cardId.length &&
    !payload.fromAmount &&
    !payload.toAmount &&
    fromTextFilter === fromTextDefault &&
    toTextFilter === toTextDefault;

  useEffect(() => {
    if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
      // @ts-ignore
      dispatch(getHistoryList(payload));
    }
    if (activeTab === HISTORY_TABS.EXPENSES) {
      // @ts-ignore
      dispatch(getExpenseList(payload));
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      // @ts-ignore
      dispatch(getIncomeList(payload));
    }
  }, [filters.search.length, activeTab, dispatch, payload]);

  return (
    <div>
      <ClickableHeader
        onBack={async () => {
          dispatch(systemActions.setActiveTab({ tab: TabVariants.history }));
        }}
        title="Поиск по операциям"
      />

      <SearchInput
        value={localSearch}
        onChange={(event) => setLocalSearch(event.target.value)}
        placeholder="Поиск по названию"
      />

      <SkeletonContainer height="570px" isLoading={isLoading} width="100%">
        {!isLoading &&
          (data[activeTab].length ? (
            <div>
              {data[activeTab].map((item) => {
                if (!item.statements?.length) return null;
                return (
                  <Box key={item.date} mb={8}>
                    <DateStyled>
                      {parseTransactionsDate(item.date || "")}
                    </DateStyled>
                    <Box display="flex" flexDirection="column">
                      {item.statements.map((el) => (
                        <OperationItem
                          key={el.statementId}
                          item={el}
                          onOpenModal={openModal}
                        />
                      ))}
                    </Box>
                  </Box>
                );
              })}
              {isLoading && (
                <Box
                  width="100%"
                  height={15}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Loader size={15} />
                </Box>
              )}
              <div ref={lastBlockRef} />
            </div>
          ) : isDefaultFilters ? (
            <EmptyScreenDefault />
          ) : (
            <EmptyScreenCustom />
          ))}
      </SkeletonContainer>
    </div>
  );
};
